import { MobileDateTimePicker } from "@mui/lab";
import { DateTimePickerView } from "@mui/lab/DateTimePicker/shared";
import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { InputProps } from "shared/Input/types";

export interface FieldDateProps extends InputProps {
	disablePast?: boolean;
	disableFuture?: boolean;
	shouldDisableDate?: (day: Date) => boolean;
	minDateTime?: Date;
	maxDateTime?: Date;
	openTo?: DateTimePickerView;
}

const FieldDateTime = ({
	name,
	disabled,
	placeholder,
	shouldDisableDate,
	minDateTime,
	maxDateTime,
	rules,
	openTo,
	label,
}: FieldDateProps) => {
	const { control } = useFormContext();

	const formattedRules = {
		...rules,
		pattern: {
			value: /^(\d{2})\.(\d{2})\.(\d{4}) (\d{2}):(\d{2})$/,
			message: "Enter a Valid Date",
		},
	};

	const test = false;

	return (
		<Controller
			control={control}
			name={name}
			render={({ field, fieldState: { invalid, error, isDirty } }) => {
				return (
					<MobileDateTimePicker
						{...field}
						label={label || ""}
						openTo={openTo}
						shouldDisableDate={shouldDisableDate}
						inputFormat="yyyy-MM-dd hh:mm a"
						disabled={disabled}
						minutesStep={5}
						ampm={false}
						renderInput={(params) => (
							<TextField
								{...params}
								placeholder="yyyy-mm-dd hh:mm "
								error={invalid}
								helperText={invalid && `${error?.message}`}
								fullWidth
								sx={{ mt: "0.5em" }}
								// type='datetime-local'
							/>
						)}
						ampmInClock={true}
						minDateTime={minDateTime}
						maxDateTime={maxDateTime}
						// disablePast={true}
						// shouldDisableTime={(time, type) => {
						// 	if (
						// 		[0, 1, 2, 3, 4, 5, 6, 7, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].includes(time) &&
						// 		type == "hours"
						// 	) {
						// 		return true;
						// 	}
						// 	return false;
						// }}
						DialogProps={{
							PaperProps: {
								sx: {
									fontWeight: 900,
								},
							},
							TransitionProps: {
								style: {
									fontVariantNumeric: "normal",
									fontOpticalSizing: "auto",
									fontWeight: 900,
									color: "blue",
								},
							},
							sx: {
								fontWeight: 900,
								color: "blue",
							},
						}}
					/>
				);
			}}
			rules={formattedRules}
			defaultValue={null}
		/>
	);
};

export default FieldDateTime;
