import { entitySelector } from "app/utility/entitySelectors";
import React from "react";
import { useGetCompletedBookingsQuery } from "../bookingServices";
import { BookingDto } from "../dto/booking.dto";

export const useCompletedBookingProvider = () => {
  const {
    data,
    error,
    isFetching: isLoading,
    refetch,
  } = useGetCompletedBookingsQuery();
  const selectors = entitySelector<BookingDto>(data);

  return React.useMemo(() => {
    return {
      data,
      error,
      isLoading,
      refetch,
      ...selectors,
    };
  }, [data, error, isLoading, refetch, selectors]);
};

export const CompletedBookingContext = React.createContext<ReturnType<
  typeof useCompletedBookingProvider
> | null>(null);

export const CompletedBookingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const props = useCompletedBookingProvider();
  return (
    <CompletedBookingContext.Provider value={props}>
      {children}
    </CompletedBookingContext.Provider>
  );
};

export const useCompletedBooking = () => {
  return React.useContext(CompletedBookingContext) as ReturnType<
    typeof useCompletedBookingProvider
  >;
};
