import React from "react";
import { TableActions, TableProps } from "shared/Table";
import { CustomFilterComponent } from "shared/Table/CustomFilterComponent";
import {
  customDateFilter,
  customTextAreaFilter,
} from "shared/Table/customFilters";
import { UserDto } from "../../../app/services/dto/user.dto";
import { useAdmin } from "../../../app/services/hooks/useAdmin";
import { useClient } from "../../../app/services/hooks/useClient";
import { useDriver } from "../../../app/services/hooks/useDriver";
import { useUpdateUserByIdMutation } from "../../../app/services/userServices";
export const userTableProps = ({
  tableHook,
  title,
  actions,
  role,
}: UserTableProps): TableProps<UserDto> => {
  const { table, refetch } = tableHook();
  const [updateUser] = useUpdateUserByIdMutation();

  const baseActions = React.useMemo(() => {
    if (role) {
      const defaultActions: TableActions<UserDto> = [
        {
          icon: "refresh",
          tooltip: "Refresh",
          isFreeAction: true,
          onClick: refetch,
        },
        // {
        //   icon: "content_copy",
        //   tooltip: "Copy to Clipboard",
        //   isFreeAction: true,
        //   onClick: refetch,
        // },
      ];

      // if (["ADMIN", "SUPERUSER"].includes(role)) {
      //   defaultActions.push({
      //     icon: "add",
      //     tooltip: "Add User",
      //     isFreeAction: true,
      //     onClick: () => console.log("editUnit"),
      //   });
      // }

      if (["SUPERUSER", "ADMIN"].includes(role)) {
        defaultActions.push((rowData) => ({
          icon: rowData?.status == "ACTIVE" ? "visibility" : "visibility_off",
          tooltip: "Enable/Disable User",
          onClick: () =>
            updateUser({
              id: rowData.id,
              status: "ACTIVE" == rowData.status ? "DISABLED" : "ACTIVE",
            }),
        }));

        // defaultActions.push({
        //   icon: "edit",
        //   tooltip: "Edit User",
        //   onClick: () => console.log("editUnit"),
        // });

        // defaultActions.push({
        //   icon: "delete",
        //   tooltip: "Remove User",
        //   onClick: () => console.log("editUnit"),
        // });

        return actions ? [...defaultActions, ...actions] : defaultActions;
      }
    } else {
      return [];
    }
  }, [role]);

  return {
    data: table as [],
    columns: [
      {
        id: "id",
        title: "Employee ID",
        field: "id",
        customFilterAndSearch: customTextAreaFilter,
      },
      {
        id: "id",
        title: "Role",
        field: "role",
      },
      { id: "id", title: "Username", field: "username" },
      {
        id: "id",
        title: "Name",
        field: "name",
      },
      {
        id: "id",
        title: "Email",
        field: "email",
      },
      {
        id: "id",
        title: "Phone",
        field: "phone",
      },
      {
        id: "id",
        title: "Status",
        field: "status",
      },
      {
        id: "id",
        title: "Creation Date",
        field: "createdAt",
        type: "date",
        filterComponent: CustomFilterComponent,
        customFilterAndSearch: customDateFilter,
      },
    ],
    getData: refetch,
    actions: baseActions,
    title: title || "Users",
  };
};

export interface UserTableProps extends Partial<TableProps<UserDto>> {
  tableHook: typeof useClient | typeof useAdmin | typeof useDriver;
  title: string;
}
