import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
} from "@mui/material";

const CheckBox = ({ onChange, checked }: CheckboxProps) => {
  return (
    <FormControl fullWidth>
      <FormControlLabel
        control={
          <Checkbox color='primary' onChange={onChange} checked={checked} />
        }
        label='Remember me'
      />
    </FormControl>
  );
};

export default CheckBox;
