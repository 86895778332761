import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { api } from "app";
import { UnitDto } from "app/services/dto/unit.dto";

const URL = "wialon/units";

const unitServices = api.injectEndpoints({
  endpoints: (build) => ({
    getUnits: build.query<EntityState<UnitDto>, void>({
      query: () => ({ url: `${URL}`, method: "GET" }),
      providesTags: [{ type: "Units" }],
      transformResponse: (response: { data: UnitDto[] }) => {
        return unitAdapter.addMany(
          unitAdapter.getInitialState(),
          response.data
        );
      },
    }),
    getUnitById: build.query<UnitDto, number>({
      query: (id) => ({ url: `${URL}/${id}`, method: "GET" }),
      providesTags: (_result, _error, id) => [{ type: "Units", id: id }],
    }),
    createUnit: build.mutation({
      query: (data: UnitDto) => ({ url: `${URL}`, method: "POST", body: data }),
      invalidatesTags: [{ type: "Units" }],
    }),
    editUnit: build.mutation({
      query: ({ id, ...data }: UnitDto) => ({
        url: `${URL}/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: [{ type: "Units" }],
    }),
  }),
  overrideExisting: false,
});

export default unitServices;

export const {
  useGetUnitsQuery,
  useCreateUnitMutation,
  useEditUnitMutation,
  useGetUnitByIdQuery,
} = unitServices;

const unitAdapter = createEntityAdapter<UnitDto>({});
