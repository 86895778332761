import { FormControl, FormHelperText, FormLabel, TextFieldProps } from "@mui/material";
import useIsMounted from "app/hooks/useIsMounted";
import { startCase, toLower } from "lodash";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import ReactSelect, { components } from "react-select";
import { InputProps } from "shared/Input/types";

const FieldSelect = ({
	label,
	name,
	disabled,
	placeholder,
	rules,
	options,
	defaultValue,
	isClearable,
	customSelectHook,
}: FieldSelectProps) => {
	const isMounted = useIsMounted();
	const { control, setValue } = useFormContext();

	React.useEffect(() => {
		if (isMounted() && defaultValue) {
			setValue(name, String(defaultValue));
		}
	}, [defaultValue]);

	return (
		<Controller
			control={control}
			name={name}
			render={({ field, fieldState: { invalid, error } }) => {
				return (
					<ReactSelect
						{...field}
						components={{
							Control: (props) => (
								<FormControl fullWidth margin="normal">
									<FormLabel>{label || startCase(toLower(name))}</FormLabel>
									{<components.Control {...props} />}
									{invalid && <FormHelperText error>{error?.message}</FormHelperText>}
								</FormControl>
							),
						}}
						value={options.find((c) => {
							const isMatch = c?.value == field?.value;
							return isMatch;
						})}
						onChange={(data) => {
							if (customSelectHook) {
								customSelectHook(data);
							}

							field.onChange(() => data && data.value);
						}}
						disabled={disabled}
						placeholder={placeholder}
						styles={{
							menuPortal: (base) => ({ ...base, zIndex: 9999 }),
							option: (provided, state) => ({
								...provided,
								color: state.data?.color || "black",
							}),
						}}
						options={options.filter((item) => {
							if ((item as unknown as { extra: { is_used: "true" | "false" } })?.extra?.is_used) {
								return (
									(
										item as unknown as {
											extra: { is_used: "true" | "false" };
										}
									)?.extra?.is_used == "false"
								);
							}
							return true;
						})}
						menuPortalTarget={document.body}
						isSearchable
						isClearable={isClearable}
					/>
				);
			}}
			defaultValue=""
			rules={rules}
		/>
	);
};

export default FieldSelect;

export interface FieldSelectProps extends InputProps {
	type?: string;
	autoComplete?: string;
	inputProps?: TextFieldProps;
	options: { label: string; value: string | number }[];
	isClearable?: boolean;
	alternateOption?: {
		labelName: string;
		valueName: string;
	};
	customSelectHook?: (data: any) => any;
}
