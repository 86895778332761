import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "app/store";

interface PageProps {
  drawerState: boolean;
}

const initialState: PageProps = {
  drawerState: false,
};

export const pageSlice = createSlice({
  name: "pages",
  initialState,
  reducers: {
    handleDrawer: (state) => {
      state.drawerState = !state.drawerState;
    },
  },
});

// Exportable Actions
export const { handleDrawer } = pageSlice.actions;

// Commonly Used Data
export const pageSelector = ({ pages }: RootState) => ({
  drawerState: pages.drawerState,
});

export default pageSlice.reducer;
