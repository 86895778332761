import { Action, MTableToolbar } from "@material-table/core";
import { DateRange, DateRangePicker } from "@mui/lab";
import { Box, Grid, TextField } from "@mui/material";
import {
  compareAsc,
  endOfDay,
  isWithinInterval,
  parseISO,
  startOfDay,
} from "date-fns";
import React from "react";
import BaseTable from "shared/Table";
import { CustomFilterComponent } from "shared/Table/CustomFilterComponent";
import { customDateFilter } from "shared/Table/customFilters";
import { useGetBookingDriverReportQuery } from "../../../app/services/bookingServices";
import { BookingDto } from "../../../app/services/dto/booking.dto";
import { useAuth } from "../../../app/services/hooks/useAuth";
import { entitySelector } from "../../../app/utility/entitySelectors";
import { CancelBookingModal } from "../../Booking/Modals/CancelBookingModal";
import { CompleteBookingModal } from "../../Booking/Modals/CompleteBookingModal";
import { EditBookingModal } from "../../Booking/Modals/EditBookingModal";
import { bookingTableProps } from "./tableProps";

export const BookingDriverReportTable = ({
  driverId,
}: {
  driverId: number;
}) => {
  const [value, setValue] = React.useState<DateRange<Date>>([null, null]);
  const [dateFilter, setDateFilter] = React.useState<null | Interval>(null);
  const {
    refetch,
    table,
    isFetching: isLoading,
  } = useGetBookingDriverReportQuery(driverId, {
    selectFromResult: ({ data, ...params }) => ({
      ...entitySelector(data),
      ...params,
    }),
  });

  const { role } = useAuth();
  const [selectedEdit, setSelectedEdit] = React.useState<null | number>(null);
  const [selectedCancel, setSelectedCancel] = React.useState<null | number>(
    null
  );
  const [selectedFinish, setSelectedFinish] = React.useState<null | number>(
    null
  );

  const bookedTableProps = bookingTableProps({
    refetch,
    table,
    isLoading,
    title: "General Booking Report",
    columns: [
      {
        id: "id",
        title: "Booking ID",
        field: "id",
        defaultSort: "desc",
      },
      {
        id: "id",
        title: "Status",
        field: "status",
        cellStyle: (rowData: string) => {
          return {
            color:
              rowData == "COMPLETED"
                ? "green"
                : rowData == "REJECTED" || rowData == "CANCELLED"
                ? "red"
                : "black",
          };
        },
      },
      {
        id: "id",
        title: "Requested Date",
        field: "dateRequested",
        type: "date",
        customFilterAndSearch: customDateFilter,
        filterComponent: CustomFilterComponent,
      },
      { id: "id", title: "Driver ID", field: "driver.id" },
      { id: "id", title: "Driver Name", field: "driver.name" },
      {
        id: "id",
        title: "Vehicle Type",
        field: "vehicleType",
      },
      {
        id: "id",
        title: "Pickup Location",
        field: "routes[0].start.address",
        hiddenByColumnsButton: true,
        hidden: true,
        export: true,
      },
      {
        id: "id",
        title: "Dropoff Location",
        field: "routes[0].finish.address",
        hiddenByColumnsButton: true,
        hidden: true,
        export: true,
      },
      {
        id: "id",
        title: "Pickup Start Time",
        field: "routes[0].start.timeStart",
        type: "datetime",
        customFilterAndSearch: customDateFilter,
        filterComponent: CustomFilterComponent,
      },
      {
        id: "id",
        title: "Pickup Finish Time",
        field: "routes[0].start.timeFinish",
        type: "datetime",
        customFilterAndSearch: customDateFilter,
        filterComponent: CustomFilterComponent,
        hiddenByColumnsButton: true,
        hidden: true,
        export: true,
      },
      {
        id: "id",
        title: "Dropoff Start Time",
        field: "routes[0].finish.timeStart",
        type: "datetime",
        customFilterAndSearch: customDateFilter,
        filterComponent: CustomFilterComponent,
      },
      {
        id: "id",
        title: "Dropoff Finish Time",
        field: "routes[0].finish.timeFinish",
        type: "datetime",
        customFilterAndSearch: customDateFilter,
        filterComponent: CustomFilterComponent,
        hiddenByColumnsButton: true,
        hidden: true,
        export: true,
      },
    ],
    role,
  });

  return (
    <>
      <CompleteBookingModal
        selected={selectedFinish}
        setSelected={setSelectedFinish}
      />
      <BaseTable
        {...bookedTableProps}
        data={
          dateFilter
            ? (bookedTableProps.data as BookingDto[]).filter((item) =>
                isWithinInterval(
                  parseISO(String(item.dateRequested)),
                  dateFilter
                )
              )
            : bookedTableProps.data
        }
        components={{
          Toolbar: (props) => (
            <Grid flex={1} direction='row-reverse' container>
              <DateRangePicker
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                  const start = newValue[0] && startOfDay(newValue[0]);
                  const end = newValue[1] && endOfDay(newValue[1]);
                  const isValid = start && end && compareAsc(end, start);
                  setDateFilter(
                    isValid
                      ? {
                          start,
                          end,
                        }
                      : null
                  );
                }}
                startText='From'
                endText='To'
                renderInput={(startProps, endProps) => (
                  <Grid
                    item
                    container
                    flex={1}
                    spacing={1}
                    padding={2}
                    alignSelf='flex-end'
                  >
                    <TextField
                      {...startProps}
                      sx={{ minWidth: 65 }}
                      size='small'
                    />
                    <Box sx={{ mx: 2, fontSize: 16, alignSelf: "center" }}>
                      to
                    </Box>
                    <TextField
                      {...endProps}
                      sx={{ minWidth: 65 }}
                      size='small'
                    />
                  </Grid>
                )}
              />
              <Grid item flex={1}>
                <MTableToolbar {...props} />
              </Grid>
            </Grid>
          ),
        }}
        actions={[...(bookedTableProps.actions as Action<BookingDto>[])]}
      />
      <EditBookingModal selected={selectedEdit} setSelected={setSelectedEdit} />
      <CancelBookingModal
        selected={selectedCancel}
        setSelected={setSelectedCancel}
      />
    </>
  );
};
