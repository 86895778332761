import NotificationsIcon from "@mui/icons-material/Notifications";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Badge, Box, Button, Tab } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import { orderBy } from "lodash";
import * as React from "react";
import {
  useGetUnreadLogsQuery,
  useUpdateReadAllMutation,
  useUpdateReadLogMutation,
} from "../../../app/services/logService";

const ITEM_HEIGHT = 48;

export const NotificationMenu = () => {
  const [value, setValue] = React.useState("1");

  const handleTabs = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const { data } = useGetUnreadLogsQuery(undefined, {
    refetchOnReconnect: true,
    pollingInterval: 10000,
  });
  const [read] = useUpdateReadLogMutation();
  const [readAll] = useUpdateReadAllMutation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRead = (id: number) => {
    read(id);
  };

  const handleClear = () => {
    readAll();
  };

  const createdBookingList = orderBy(
    (data || []).filter(
      (item) =>
        item?.message?.type == "booking" &&
        ["create"].includes(item?.message?.action || "")
    ),
    ["id"],
    ["desc"]
  );

  const updateBookingList = orderBy(
    (data || []).filter(
      (item) =>
        item?.message?.type == "booking" &&
        ["update"].includes(item?.message?.action || "")
    ),
    ["id"],
    ["desc"]
  );

  return (
    <>
      <IconButton
        aria-label='show new notifications'
        onClick={handleClick}
        size='large'
        color='inherit'
      >
        <Badge badgeContent={data && data.length} color='error'>
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        id='long-menu'
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 400,
            maxWidth: 400,
          },
        }}
      >
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabs} aria-label='lab API tabs example'>
                <Tab label='New Bookings' value='1' />
                <Tab label='Updates' value='2' />
                <Button onClick={handleClear}>Clear All</Button>
              </TabList>
            </Box>
            <TabPanel value='1'>
              {createdBookingList
                ? createdBookingList.map((option, index) => (
                    <MenuItem key={index} onClick={() => handleRead(option.id)}>
                      <ListItemAvatar>
                        <Avatar alt={option.message.type} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={String(option.message.details)}
                        secondary={
                          <>
                            <Typography
                              sx={{ display: "inline" }}
                              component='span'
                              variant='body2'
                              color='text.primary'
                            >
                              {String(
                                format(
                                  new Date(option.message.createdAt),
                                  "MMM dd, yyyy"
                                )
                              )}
                            </Typography>
                          </>
                        }
                      />
                    </MenuItem>
                  ))
                : null}
            </TabPanel>
            <TabPanel value='2'>
              {updateBookingList
                ? updateBookingList.map((option, index) => (
                    <MenuItem key={index} onClick={() => handleRead(option.id)}>
                      <ListItemAvatar>
                        <Avatar alt={option.message.type} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={String(option.message.details)}
                        secondary={
                          <>
                            <Typography
                              sx={{ display: "inline" }}
                              component='span'
                              variant='body2'
                              color='text.primary'
                            >
                              {String(
                                format(
                                  new Date(option.message.createdAt),
                                  "MMM dd, yyyy"
                                )
                              )}
                            </Typography>
                          </>
                        }
                      />
                    </MenuItem>
                  ))
                : null}
            </TabPanel>
          </TabContext>
        </Box>
      </Menu>
    </>
  );
};
