import { Divider, ListItemButton, Theme } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { SxProps } from "@mui/system";
import { Link } from "react-router-dom";

export interface LinkedItemProps {
  title: string;
  icon: JSX.Element;
  isAllowed: boolean;
  path?: string;
  isNested?: boolean;
  route?: LinkedItemProps[];
  sx?: SxProps<Theme>;
  hasDivider?: boolean;
}

const LinkedItem = ({ title, path, icon, sx, hasDivider }: LinkedItemProps) => {
  return (
    <>
      {hasDivider && <Divider />}
      <ListItemButton component={Link} to={path || ""} sx={sx}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    </>
  );
};

export default LinkedItem;
