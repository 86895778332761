import BaseTable from "shared/Table";
import { UnitTableProps } from "./tableProps";

export const UnitTable = () => {
  const props = UnitTableProps();

  return <BaseTable {...props} />;
};

export default UnitTable;
