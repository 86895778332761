import styled from "@emotion/styled";
import { Grid, Theme, Typography } from "@mui/material";
import { useAuth } from "app/services/hooks/useAuth";
import { theme } from "config/theme";
import { pick } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import SubmitButton from "shared/Display/Buttons/Submit";
import Copyright from "shared/Display/Copyright";

const FormGrid = ({ fields, title }: FormGridProps) => {
  const methods = useForm();
  const { handleLogin } = useAuth();

  const onSubmit = methods.handleSubmit((data) => {
    const rememberMe = localStorage.getItem("rememberMe");
    if (rememberMe) {
      const { username, password } = data;
      localStorage.setItem("rememberMeUsername", username);
      localStorage.setItem("rememberMePassword", password);
    }
    handleLogin(pick(data, ["username", "password"]));
  });

  return (
    <Grid
      item
      container
      sx={{
        alignItems: "center",
        flex: 1,
        verticalAlign: "middle",
        margin: theme.spacing(8, 4),
        display: "flex",
      }}
    >
      <FormProvider {...methods}>
        {/* <LoginLogo src={`/img/AmanaLogoT-min.png`} /> */}

        <Typography component='h1' variant='h5'>
          {title}
        </Typography>

        {fields.map(({ component: Component, props }, index) => {
          return <Component {...props} key={index} />;
        })}

        {<SubmitButton theme={theme} text='Sign in' onClick={onSubmit} />}

        <Copyright />
      </FormProvider>
    </Grid>
  );
};

export default FormGrid;

interface FormGridProps {
  title: string;
  fields: { component: React.ComponentType; props?: Record<string, unknown> }[];
}

const LoginLogo = styled("img")({
  height: "auto",
  maxHeight: "30vh",
  backgroundPosition: "center center",
  backgroundSize: "cover",
  objectFit: "contain",
  width: "100%",
});

const StyledFormGrid = styled(Grid)(({ theme }: { theme: Theme }) => ({
  margin: theme.spacing(8, 4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));
