import React from "react";
import { useHistory } from "react-router-dom";
import BaseTable from "shared/Table";
import { UserDto } from "../../../app/services/dto/user.dto";
import { useAuth } from "../../../app/services/hooks/useAuth";
import { useClient } from "../../../app/services/hooks/useClient";
import { CreateUserModal } from "../../User/Modal/CreateUserModal";
import { EditUserModal } from "../../User/Modal/EditUserModal";
import { userTableProps } from "./tableProps";

export const ClientTable = () => {
  const history = useHistory();
  const [selectedEdit, setSelectedEdit] = React.useState<null | number>(null);
  const [selectedCreate, setSelectedCreate] = React.useState<boolean>(false);
  const { role } = useAuth();
  const props = userTableProps({
    tableHook: useClient,
    title: "Clients",
    role,
    actions: [
      {
        icon: "edit",
        tooltip: "Edit User",
        onClick: (_e, rowData) => {
          const isRowData = rowData as UserDto;
          if (isRowData?.id) {
            setSelectedEdit(isRowData.id);
          }
        },
      },
      {
        icon: "add",
        tooltip: "Add User",
        isFreeAction: true,
        onClick: () => {
          setSelectedCreate(true);
        },
      },
    ],
  });

  return (
    <>
      <BaseTable {...props} />
      <EditUserModal selected={selectedEdit} setSelected={setSelectedEdit} />
      <CreateUserModal
        selected={selectedCreate}
        setSelected={setSelectedCreate}
        type='CLIENT'
      />
    </>
  );
};

export default ClientTable;
