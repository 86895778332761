import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import type {} from "@mui/lab/themeAugmentation";
import { Backdrop, Box, CircularProgress, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useAuth } from "app/services/hooks/useAuth";
import { theme } from "config/theme";
import AdminBase from "features/Admin/AdminPage";
import React from "react";
import "react-datetime/css/react-datetime.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import LoginScreen from "./Admin/LoginPage";
import { BookingListProvider } from "./Booking/Providers/BookingListProvider";
import { UserListProvider } from "./User/Providers/UserListProvider";

const App = () => {
  const { isAuthenticated, isLoading } = useAuth();
  return (
    <ThemeProvider theme={theme}>
      <EmotionThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <ToastContainer />
          {isAuthenticated ? (
            <BookingListProvider>
              <UserListProvider>
                <AdminBase />
              </UserListProvider>
            </BookingListProvider>
          ) : (
            <LoginScreen />
          )}
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        </Box>
      </EmotionThemeProvider>
    </ThemeProvider>
  );
};

export default App;
