import React from "react";
import { useHistory } from "react-router-dom";
import BaseTable from "shared/Table";
import { useGetCompletedBookingsQuery } from "../../../app/services/bookingServices";
import { BookingDto } from "../../../app/services/dto/booking.dto";
import { useAuth } from "../../../app/services/hooks/useAuth";
import { entitySelector } from "../../../app/utility/entitySelectors";
import { CancelBookingModal } from "../../Booking/Modals/CancelBookingModal";
import { EditBookingModal } from "../../Booking/Modals/EditBookingModal";
import { bookingTableProps } from "./tableProps";

export const CompletedBookingsTable = () => {
  const {
    refetch,
    table,
    isFetching: isLoading,
  } = useGetCompletedBookingsQuery(undefined, {
    selectFromResult: ({ data, ...params }) => ({
      ...entitySelector(data),
      ...params,
    }),
  });

  const history = useHistory();
  const [selectedEdit, setSelectedEdit] = React.useState<null | number>(null);
  const [selectedCancel, setSelectedCancel] = React.useState<null | number>(
    null
  );
  const { role } = useAuth();

  const completedTableProps = bookingTableProps({
    refetch,
    table,
    isLoading,
    title: "Completed Trips",
    actions: ["ADMIN", "SUPERUSER"].includes(role)
      ? [
          {
            icon: "pageview",
            tooltip: "View Trip",
            onClick: (_e, rowData) => {
              history.push("/bookings/view/" + (rowData as BookingDto).id);
            },
          },
        ]
      : [],
    role,
  });

  return (
    <>
      <BaseTable {...completedTableProps} />
      <EditBookingModal selected={selectedEdit} setSelected={setSelectedEdit} />
      <CancelBookingModal
        selected={selectedCancel}
        setSelected={setSelectedCancel}
      />
    </>
  );
};
