import { createEntityAdapter } from "@reduxjs/toolkit";
import { api, TAG_TYPES } from "app";
import { BookingDto } from "./dto/booking.dto";
import { NotificationDto } from "./dto/log.dto";

const URL = "logs";

const logService = api.injectEndpoints({
  endpoints: (build) => ({
    getUnreadLogs: build.query<NotificationDto[], void>({
      query: () => ({
        url: `${URL}/unread`,
        method: "GET",
        headers: {
          options: JSON.stringify({ rid: true }),
        },
      }),
      providesTags: TAG_TYPES,
    }),
    updateReadLog: build.mutation<NotificationDto, number>({
      query: (id) => ({
        url: `${URL}/${id}/read`,
        method: "POST",
        headers: {
          options: JSON.stringify({ rid: true }),
        },
      }),
      invalidatesTags: TAG_TYPES,
    }),
    updateReadAll: build.mutation<NotificationDto, void>({
      query: () => ({
        url: `${URL}/read-all`,
        method: "POST",
        headers: {
          options: JSON.stringify({ rid: true }),
        },
      }),
      invalidatesTags: TAG_TYPES,
    }),
  }),
  overrideExisting: false,
});

export default logService;

export const {
  useGetUnreadLogsQuery,
  useUpdateReadLogMutation,
  useLazyGetUnreadLogsQuery,
  useUpdateReadAllMutation,
} = logService;

const logAdapter = createEntityAdapter<BookingDto>({});
