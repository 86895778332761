import { Card, CardHeader, Grid } from "@mui/material";
import { LatLngLiteral, Map } from "leaflet";
import { ProjectLocationDto } from "../../../app/slices/dto/select-marker.dto";
import { OrderCreationForm } from "../../Orders/Forms/OrderCreationForm";

export const BookingCreationPanel = ({
  latLng,
  project,
  map,
}: BookingCreationPanelProps) => {
  return (
    <Grid
      item
      container
      md={4}
      rowSpacing={2}
      direction='column'
      style={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
      }}
    >
      <Card
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <CardHeader
          title='Booking Details'
          subheader='Kindly double-click on the Map to select a Location'
        />
        {latLng && (
          <OrderCreationForm latLng={latLng} project={project} map={map} />
        )}
      </Card>
    </Grid>
  );
};

export type BookingCreationPanelProps = {
  latLng: LatLngLiteral | null;
  map?: Map | null;
} & Partial<ProjectLocationDto>;
