import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import BookIcon from "@mui/icons-material/Book";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import React from "react";
import { useGetBookingCountQuery } from "../../../app/services/bookingServices";

export const BookingCountFields = () => {
  const { data, isFetching } = useGetBookingCountQuery();

  const updatedBookingStatus = React.useMemo(() => {
    const bookingStatus = [
      {
        title: "Pending Trips",
        field: (term: string) => ["PENDING", "TO_CANCEL"].includes(term),
        count: 0,
        color: "#fbb124",
        icon: <BookIcon />,
      },
      {
        title: "Booked Trips",
        field: (term: string) =>
          [
            "ACCEPTED",
            "INPROGRESS",
            "CONFIRMATION",
            "FINALIZING",
            "POSTPONE",
          ].includes(term),
        count: 0,
        color: "blue",
        icon: <ScheduleIcon />,
      },
      {
        title: "Completed Trips",
        field: (term: string) =>
          ["COMPLETED", "FINISHED", "COMPLETED"].includes(term),
        count: 0,
        color: "green",
        icon: <AssignmentTurnedInIcon />,
      },
      {
        title: "Cancelled Trips",
        field: (term: string) => ["REJECTED", "CANCELLED"].includes(term),
        count: 0,
        color: "ef5350",
        icon: <ThumbDownAltIcon />,
      },
    ];

    if (data) {
      return bookingStatus.map((item) => {
        data.forEach(({ count, status }) => {
          if (item.field(status)) {
            item.count += Number(count);
          }
        });
        return item;
      });
    }

    return bookingStatus;
  }, [data]);

  return isFetching ? (
    <LinearProgress />
  ) : (
    <Grid container spacing={5} alignItems='flex-end'>
      {updatedBookingStatus.map((params, index) => (
        <Grid item key={params.title + index} xs={12} sm={6} md={4}>
          <Card>
            <CardHeader
              title={
                <Typography variant='h6' align='center' color='textSecondary'>
                  {params.title}
                </Typography>
              }
              titleTypographyProps={{ align: "center" }}
              subheaderTypographyProps={{ align: "center" }}
              avatar={
                <Avatar variant='square' sx={{ backgroundColor: params.color }}>
                  {params.icon}
                </Avatar>
              }
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[50]
                    : theme.palette.grey[100],
              }}
            />
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "baseline",
              }}
            >
              <Typography component='h1' variant='h2' color='textPrimary'>
                {params.count}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export interface CreateBookingFieldProps {
  id?: number;
}
