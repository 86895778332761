import { UnitDto } from "app/services/dto/unit.dto";
import { useUnit } from "app/services/hooks/useUnit";
import { TableProps } from "shared/Table";
import { customTextAreaFilter } from "shared/Table/customFilters";

export const UnitTableProps = (): TableProps<UnitDto> => {
  const { refetch, table, isLoading } = useUnit();

  return {
    data: table as [],
    columns: [
      {
        id: "id",
        title: "ID",
        field: "id",
        customFilterAndSearch: customTextAreaFilter,
      },
      {
        id: "id",
        title: "Vehicle",
        field: "name",
      },
    ],
    getData: refetch,
    actions: [
      {
        icon: "refresh",
        tooltip: "Refresh",
        isFreeAction: true,
        onClick: refetch,
      },
      {
        icon: "content_copy",
        tooltip: "Copy to Clipboard",
        isFreeAction: true,
        onClick: console.log,
      },
      // {
      //   icon: "edit",
      //   tooltip: "Edit Unit",
      //   onClick: (_e, rowData) => {
      //     const id = Array.isArray(rowData) ? 0 : rowData.id;
      //     console.log("editUnit", id);
      //   },
      // },
    ],
    title: "Units",
    isLoading,
  };
};
