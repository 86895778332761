import { api } from "app";
import { JobDto } from "./dto/job.dto";

const URL = "jobs";

const jobServices = api.injectEndpoints({
  endpoints: (build) => ({
    getJobById: build.query<JobDto, number | string>({
      query: (id) => ({ url: `${URL}/${id}`, method: "GET" }),
      providesTags: [{ type: "Jobs" }],
    }),
  }),
  overrideExisting: false,
});

export default jobServices;

export const {
  useGetJobByIdQuery,
} = jobServices;


