import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { BookingDto } from "../../../app/services/dto/booking.dto";
import { useGetUnitByIdQuery } from "../../../app/services/unitServices";
import { useGetUserByIdQuery } from "../../../app/services/userServices";
import { ImageViewModal } from "../../Booking/Modals/ImageViewModal";
export const LocationDetailPanelSummaryViewer = ({
  id,
  booking,
  markers,
  route,
  start,
  finish,
  distanceFromStart,
  distanceFromTrip,
  distanceFromTotal,
}: any) => {
  const { data: vehicle } = useGetUnitByIdQuery(booking?.vehicle || 0);
  const { data: user } = useGetUserByIdQuery(
    String(booking?.user as number) || ""
  );
  const { data: driver } = useGetUserByIdQuery(
    String(booking?.driver as number) || ""
  );

  const getTotalTime = (timeStart: Date, timeFinish: Date) => {
    if (timeStart && timeFinish) {
      try {
        const start = moment(new Date(timeStart));
        const end = moment(new Date(timeFinish));

        if (start == end) {
          return "0";
        }
        const isValidInterval = end >= start;
        if (isValidInterval) {
          const totalInterval = moment.duration(
            end.diff(start) / 3600000,
            "hours"
          );
          const intervalString: (number | string)[] = [];

          const intervalHours = Math.floor(totalInterval.asHours());
          const intervalMinutes =
            Math.floor(totalInterval.asMinutes()) - intervalHours * 60;
          const intervalSeconds =
            Math.floor(totalInterval.asSeconds()) -
            intervalHours * 60 * 60 -
            intervalMinutes * 60;

          intervalString.push(intervalHours, intervalMinutes, intervalSeconds);
          return `${intervalHours > 9 ? intervalHours : "0" + intervalHours}:${
            intervalMinutes > 9 ? intervalMinutes : "0" + intervalMinutes
          }:${intervalSeconds > 9 ? intervalSeconds : "0" + intervalSeconds}`;
        }
        return null;
      } catch {
        return null;
      }
    }
    return null;
  };

  const addTotalTime = (timeStart: Date, timeFinish: Date) => {
    // const startTrip = {booking?.dateStart as Date,
    //   route?.timeStart as Date}
    // const finishTrip = {booking?.dateStart as Date,
    //   route?.timeStart as Date}

    if (timeStart && timeFinish) {
      try {
        const start = moment(new Date(timeStart));
        const end = moment(new Date(timeFinish));

        if (start == end) {
          return "0";
        }
        const isValidInterval = end >= start;
        if (isValidInterval) {
          const totalInterval = moment.duration(end.diff(start));
          const intervalString: (number | string)[] = [];

          intervalString.push(
            totalInterval.hours()
              ? totalInterval.hours().toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })
              : "00"
          );
          intervalString.push(
            totalInterval.minutes()
              ? totalInterval.minutes().toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })
              : "00"
          );
          intervalString.push(
            totalInterval.seconds()
              ? totalInterval.seconds().toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })
              : "00"
          );

          return intervalString.join(":") || "";
        }
        return null;
      } catch {
        return null;
      }
    }
    return null;
  };

  const calculateAnalytics = (requestedTime: Date, actualTime: Date) => {
    if (requestedTime && actualTime) {
      try {
        const formattedRequestedTime = moment(new Date(requestedTime));
        const formattedActualTime = moment(new Date(actualTime));

        const totalInterval = moment.duration(
          formattedActualTime.diff(formattedRequestedTime)
        );

        const minuteDifference = totalInterval.asMilliseconds() / 60000;
        const errorValue = minuteDifference / 1330;
        const percentageError = errorValue * 100;
        const totalPercentage = Math.floor(100 - percentageError);

        const analyticStatus = totalPercentage > 100 ? "Early" : "Late";

        return `${totalPercentage}% (${Math.floor(
          minuteDifference
        )} mins ${analyticStatus})`;
      } catch {
        return null;
      }
    }
    return null;
  };

  const contextFields = [
    {
      header: "Trip Details",
      fields: [
        booking && {
          label: "Charge to Project",
          value: booking.chargeProject,
          size: 3,
        },
        user && {
          label: "Requestor Name",
          value: user?.name,
          size: 3,
        },
        driver && {
          label: "Driver",
          value: driver.name || booking.driver,
          size: 3,
        },
        vehicle && {
          label: "Vehicle",
          value: vehicle.name || booking.vehicle,
          size: 3,
        },
        vehicle && {
          label: "Vehicle Type",
          value: booking.vehicleType,
          size: 3,
        },
        start && {
          label: "Description",
          value: start?.description,
          size: 3,
        },
      ],
    },
    {
      header: "Trip Summary",
      fields: [
        route && {
          label: "Pickup Time",
          value: start?.timeStart,
          size: 3,
        },
        finish && {
          label: "Dropoff Time",
          value: finish?.timeStart,
          size: 3,
        },
        start && {
          label: "Trip Time",
          value: getTotalTime(
            start?.timeStart as Date,
            route?.timeFinish as Date
          ),
          size: 3,
        },
        distanceFromTrip && {
          label: "Trip Distance",
          value: distanceFromTrip,
          size: 3,
        },
        finish && {
          label: "Total Time Consumed",
          value: getTotalTime(
            booking?.dateStart as Date,
            finish?.timeFinish as Date
          ),
          size: 3,
        },
        distanceFromTotal && {
          label: "Total Distance",
          value: distanceFromTotal,
          size: 3,
        },
      ],
    },
    {
      header: "Pickup Details",
      fields: [
        start && {
          label: "Pickup Project Code",
          value: start?.project,
          size: 3,
        },
        start && {
          label: "Pickup Address",
          value: start?.address,
          size: 3,
        },
        start && {
          label: "Loading Time Started",
          value: start?.timeStart,
          size: 3,
        },
        start && {
          label: "Loading Time Finished",
          value: start?.timeFinish,
          size: 3,
        },
        start && {
          label: "Loading Time",
          value: getTotalTime(
            start?.timeStart as Date,
            start?.timeFinish as Date
          ),
          size: 3,
        },
        start && {
          label: "Loading Image Proof",
          value: start?.imageProof,
          size: 6,
          type: "url",
        },
      ],
    },
    {
      header: "Drop Off Details",
      fields: [
        finish && {
          label: "Dropoff Project Code",
          value: finish?.project,
          size: 3,
        },
        finish && {
          label: "Dropoff Address",
          value: finish?.address,
          size: 3,
        },
        finish && {
          label: "Unloading Time Started",
          value: finish?.timeStart,
          size: 3,
        },
        finish && {
          label: "Unloading Time Finished",
          value: finish?.timeFinish,
          size: 3,
        },
        finish && {
          label: "Unloading Time",
          value: getTotalTime(
            finish?.timeStart as Date,
            finish?.timeFinish as Date
          ),
          size: 3,
        },
        finish && {
          label: "Unloading Image Proof",
          value: finish?.imageProof,
          size: 6,
          type: "url",
        },
      ],
    },
    {
      header: "Analytics",
      fields: [
        start && {
          label: "Requested Loading Time",
          value: start?.timeRequested,
          size: 3,
        },
        start && {
          label: "Actual Loading Time",
          value: start?.timeStart,
          size: 3,
        },
        start && {
          label: "Accuracy",
          value: calculateAnalytics(
            start?.timeRequested as Date,
            start?.timeStart as Date
          ),
          size: 3,
        },
      ],
    },
  ];

  return booking && start && finish ? (
    <Grid item container component={CardContent}>
      {contextFields.map((context, index) => (
        <Grid item container key={context?.header + index} component={Card}>
          <CardHeader title={context?.header || ""} />
          <Grid
            item
            container
            key={context?.header + index}
            component={CardContent}
          >
            {context?.fields?.map((field, index) => (
              <Grid
                key={index}
                item
                lg={(field?.size as any) || 6}
                rowSpacing={2}
                component={CardActions}
              >
                {field?.type == "url" ? (
                  <ImageViewModal
                    url={field?.value}
                    title={field?.label || "Image View"}
                  />
                ) : (
                  <TextField
                    label={field?.label || ""}
                    variant='filled'
                    fullWidth
                    value={field?.value || ""}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      sx: {
                        fontWeight: 600,
                      },
                    }}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  ) : null;
};

export type LocationDetailPanelProps = {
  id: string | number;
  markers?: Record<string, any>[];
  booking?: BookingDto;
};
