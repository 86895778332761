import { Backdrop, CircularProgress } from "@mui/material";
import { Polygon, PolygonProps, Tooltip } from "react-leaflet";
import { useGeofence } from "../../../app/services/hooks/useGeofence";

export const GeofenceLoader = ({ handleProject }: GeofenceLoaderProps) => {
  const { list, isLoading } = useGeofence();

  return (
    <>
      {list
        ? list.map((polygon) =>
            polygon?.p ? (
              <GeofencePolygon
                key={polygon?.id}
                positions={polygon?.p}
                color={String(polygon?.c)}
                name={polygon.n}
                id={polygon.id}
                handleProject={handleProject}
              />
            ) : null
          )
        : null}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  );
};

export interface GeofenceLoaderProps {
  handleProject: (params: GeofencePolygonProps) => void;
}

interface GeofencePolygonProps {
  name: string;
  id: number;
}

const GeofencePolygon = ({
  positions,
  color,
  name,
  id,
  handleProject,
}: GeofencePolygonProps & PolygonProps & GeofenceLoaderProps) => {
  const eventHandlers = {
    dblclick() {
      if (handleProject) {
        if (name && id) {
          handleProject({ name, id });
        }
      }
    },
  };

  return (
    <Polygon
      positions={positions}
      color={String(color)}
      eventHandlers={eventHandlers}
    >
      <Tooltip>{name}</Tooltip>
    </Polygon>
  );
};
