import { TextField, TextFieldProps } from "@mui/material";
import useIsMounted from "app/hooks/useIsMounted";
import _ from "lodash";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { InputProps } from "shared/Input/types";

export interface FieldTextProps extends InputProps {
  type?: string;
  autoComplete?: string;
  inputProps?: TextFieldProps;
}

const FieldText = ({
  label,
  name,
  disabled,
  placeholder,
  rules,
  type,
  autoComplete,
  defaultValue,
  inputProps,
}: FieldTextProps) => {
  const isMounted = useIsMounted();
  const { control, setValue } = useFormContext();
  const options: TextFieldProps = {
    label: label || _.startCase(_.toLower(name)),
    disabled: disabled,
    placeholder: placeholder,
    variant: "standard",
    color: "primary",
    type: type,
    autoComplete: autoComplete,
    fullWidth: true,
    ...inputProps,
  };

  React.useEffect(() => {
    if (isMounted() && defaultValue) {
      setValue(name, String(defaultValue));
    }
  }, [defaultValue]);

  return control ? (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <TextField
            {...field}
            {...options}
            error={fieldState.invalid}
            helperText={fieldState.invalid && `${fieldState.error?.message}`}
            sx={{ mt: "0.5em" }}
          />
        );
      }}
      defaultValue=''
      rules={rules}
    />
  ) : null;
};

export default FieldText;
