import React from "react";
import { toast } from "react-toastify";
import { useLazyGetUnreadLogsQuery } from "../services/logService";

export const useSseProvider = () => {
	const [getLogs] = useLazyGetUnreadLogsQuery();
	const [eventSource, setEventSource] = React.useState<EventSource | null>(null);

	const subscribeUrl = process.env.REACT_APP_API_URL + "/sse";

	const subscribe = (role: string, id: number) => {
		if (eventSource == null) {
			const es = new EventSource(subscribeUrl);
			setEventSource(es);
		}
		if (eventSource) {
			eventSource.onmessage = ({ data }) => {
				getLogs();
				const { targets, message } = JSON.parse(data) as MessageProps;
				const isTargeted = targets.some((item) => {
					if (item == "admin" && ["ADMIN", "SUPERUSER"].includes(role)) {
						return true;
					}
					if (item == id) {
						return true;
					}
					return false;
				});
				if (isTargeted) {
					toast.info(message);
				}
			};
		}
	};

	const unsubscribe = async () => {
		if (eventSource) {
			eventSource?.close();
			setEventSource(null);
		}
	};

	return React.useMemo(() => {
		return {
			subscribe,
			unsubscribe,
		};
	}, [subscribe, unsubscribe]);
};

export const SseContext = React.createContext<ReturnType<typeof useSseProvider> | null>(null);

export const SseProvider = ({ children }: { children: React.ReactNode }) => {
	const props = useSseProvider();

	return <SseContext.Provider value={props}>{children}</SseContext.Provider>;
};

export const useSse = () => {
	return React.useContext(SseContext) as ReturnType<typeof useSseProvider>;
};

interface MessageProps {
	targets: (string | number)[];
	type: string;
	message: string;
}
