import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import {
  Avatar,
  Button,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { LatLngLiteral, Map } from "leaflet";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { GisRouteWialonDto } from "../../../app/services/dto/gis-route.dto";
import { ProjectLocationDto } from "../../../app/slices/dto/select-marker.dto";
import { useAppDispatch } from "../../../app/slices/generic";
import {
  MarkerProps,
  removeMarker,
  reorder,
} from "../../../app/slices/markerSlice";
import { SelectOptions } from "../../../config/types";
export const JobListPanel = ({
  markers,
  map,
}: {
  markers: MarkerProps[];
  map: Map;
}) => {
  // a little function to help us with reordering the result
  const dispatch = useAppDispatch();
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    dispatch(
      reorder({
        startIndex: result.source.index,
        endIndex: result.destination.index,
      })
    );
  };

  const handleRemoveMarker = (order: number) => {
    dispatch(removeMarker(order));
  };

  const gotoLocation = (latlng: LatLngLiteral) => {
    map.setView(latlng, 15, {
      animate: true,
      duration: 1,
    });
  };

  return (
    <>
      <Timeline position='right'>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable'>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {markers.map((item, index) => {
                  // const dateRequested =
                  //   String(item.dateRequested) ||
                  //   String(((item as unknown) as JobDto)?.timeRequested) ||
                  //   null;

                  // const formattedDateRequested = dateRequested
                  //   ? isNumber(dateRequested)
                  //     ? format(fromUnixTime(dateRequested), "yyyy-MM-dd hh:mm")
                  //     : String(
                  //         format(
                  //           new Date(dateRequested),
                  //           "MMM dd, yyyy hh:mm a"
                  //         )
                  //       )
                  //   : null;

                  return item ? (
                    <Draggable
                      key={item?.project + "-" + index}
                      draggableId={String(item?.project + "-" + index)}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        return (
                          <TimelineItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            key={item?.project + "-" + index}
                          >
                            <TimelineOppositeContent
                              color='text.secondary'
                              style={{
                                maxWidth: 50,
                              }}
                            >
                              {/* {formattedDateRequested} */}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineDot />
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                              <CardHeader
                                avatar={
                                  <IconButton
                                    edge='end'
                                    aria-label='delete'
                                    onClick={() =>
                                      gotoLocation({
                                        lat: item.lat,
                                        lng: item.lng,
                                      })
                                    }
                                  >
                                    <Avatar>
                                      <LocationSearchingIcon />
                                    </Avatar>
                                  </IconButton>
                                }
                                title={
                                  <Grid>
                                    <Typography variant='subtitle2'>
                                      {item?.project}
                                    </Typography>
                                    <Grid>
                                      <Chip
                                        label={index ? "DROPOFF" : "PICKUP"}
                                        color={index ? "error" : "success"}
                                      />
                                    </Grid>
                                  </Grid>
                                }
                                subheader={item?.address}
                                action={
                                  <Button
                                    aria-label='delete'
                                    color='error'
                                    onClick={() =>
                                      handleRemoveMarker(item.order)
                                    }
                                  >
                                    CLEAR
                                  </Button>
                                }
                              />
                            </TimelineContent>
                          </TimelineItem>
                        );
                      }}
                    </Draggable>
                  ) : null;
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Timeline>
    </>
  );
};

export type LocationDetailPanelProps = {
  latLng: LatLngLiteral;
} & Partial<ProjectLocationDto>;

export interface LocationDetailsDto {
  latLng: LatLngLiteral;
  project?: SelectOptions | null;
  data?: GisRouteWialonDto;
}
