import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import authServices from "../services/authServices";
import { useAppDispatch } from "../slices/generic";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const errorHandleMiddleware: Middleware = (_api: MiddlewareAPI) => (
  next
) => (action) => {
  if (isRejectedWithValue(action)) {
    console.warn("We got a rejected action!");
    const error = action?.payload?.data?.message;
    console.log(action?.payload?.data);
    try {
      if (error) {
        if (Array.isArray(error)) {
          error.forEach((err) => {
            const constraints = Object.values<string>(err.constraints);
            if (constraints) {
              constraints.forEach((constraint) => toast.warn(constraint));
            }
          });
        } else {
          const wialonError = error == "Wialon Error";
          if (wialonError) {
            console.log(wialonError);
            useAppDispatch()(authServices.endpoints.getWialonToken.initiate());
          }
          toast.warn(error);
        }
      }
    } catch {
      console.warn("Error showing notification");
    }
  }

  return next(action);
};
