import { Button, Container, Grid, Toolbar, Typography } from "@mui/material";
import { useHistory } from "react-router";
import { useAuth } from "../../app/services/hooks/useAuth";
import { BookingCountFields } from "../Booking/Fields/BookingCountFields";

const Display = () => {
  const { role } = useAuth();
  const history = useHistory();

  return (
    <Grid>
      {role === "CLIENT" && (
        <>
          <Container maxWidth='lg'>
            <Typography
              component='h3'
              variant='h3'
              align='center'
              color='textPrimary'
              gutterBottom
            >
              Amana Vehicle Booking
            </Typography>
          </Container>
          <Container maxWidth='sm'>
            <Typography
              variant='h5'
              align='center'
              color='textSecondary'
              paragraph
            >
              Click on the button to book a trip
            </Typography>
            <Grid>
              <Button
                variant='contained'
                fullWidth
                color='primary'
                style={{ backgroundColor: "red" }}
                onClick={() => history.push(`/bookings/create`)}
              >
                <Typography component='h2' variant='h3' align='center'>
                  Book a Trip
                </Typography>
              </Button>
            </Grid>
          </Container>
          <Toolbar />
        </>
      )}
      <BookingCountFields />
    </Grid>
  );
};

export default Display;
