import { Grid } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../app/services/hooks/useAuth";
import { BookingReviewPanelModal } from "../components/BookingReviewPanelModal";

export const ViewBookingSummary = () => {
  const { id } = useParams<{ id: string }>();
  const { role } = useAuth();

  return (
    <Grid container sx={{ flex: 1, flexDirection: "row" }} columnSpacing={2}>
      <Grid component={BookingReviewPanelModal} id={id} />
    </Grid>
  );
};
