import CloseIcon from "@mui/icons-material/Close";
import RouterIcon from "@mui/icons-material/Router";
import {
	Button,
	CardHeader,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	Typography,
} from "@mui/material";
import { FC } from "react";

export const ModalForm: FC<ModalFormProps> = ({
	children,
	open,
	handleClose,
	handleSubmit,
	headerProps,
	submitProps,
	cancelProps,
	maxWidth,
}) => {
	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth={maxWidth || "sm"} sx={{ paddingX: 5 }}>
			<DialogTitle>
				<CardHeader
					title={headerProps.title}
					subheader={headerProps.subheader}
					avatar={headerProps.icon || <RouterIcon fontSize="large" />}
					action={
						<IconButton size="large" onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					}
					sx={{ marginY: 0 }}
				/>
			</DialogTitle>
			<Divider />
			<DialogContent sx={{ paddingX: 5 }}>
				{children}
				<DialogActions>
					<Button color="info" variant="contained" onClick={handleClose}>
						<Typography>{cancelProps?.text || "Cancel"}</Typography>
					</Button>
					{handleSubmit && (
						<Button onClick={handleSubmit}>
							<Typography>{submitProps?.text || "Submit"}</Typography>
						</Button>
					)}
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};

export default ModalForm;

export interface ModalFormProps extends ModalStateProps {
	handleSubmit?: (v: any) => void;
	headerProps: ModalHeaderProps;
	submitProps?: ModalButtonProps;
	cancelProps?: ModalButtonProps;
	maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
}

export interface ModalButtonProps {
	text: string;
}

export interface ModalStateProps {
	open: boolean;
	handleClose: () => void;
}

export interface ModalHeaderProps {
	title: string;
	subheader: string;
	icon?: string | React.ComponentType;
}

export interface ModalFormDto {
	title: string;
	subheader: string;
	icon?: string | React.ComponentType;
}
