import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import ModalForm from "shared/Modal";
import { EditModalProps } from "../../../app/hooks/useModal";
import { useUpdateBookingByIdMutation } from "../../../app/services/bookingServices";
import { BookingDto } from "../../../app/services/dto/booking.dto";
import { UserDto } from "../../../app/services/dto/user.dto";
import { ReviewBookingForm } from "../Forms/ReviewBookingForm";

export const EditBookingModal = ({ selected, setSelected }: EditModalProps) => {
  if (selected != null) {
    const methods = useForm();
    const history = useHistory();
    const [updateBooking] = useUpdateBookingByIdMutation();

    const open = React.useMemo(() => Boolean(selected != null), [selected]);
    const handleClose = React.useCallback(() => setSelected(null), [
      setSelected,
    ]);

    const handleSubmit = React.useCallback(
      methods.handleSubmit<BookingDto>((params: BookingDto) => {
        if (selected) {
          params.id = selected;
          params.driver = { id: params.driver } as UserDto;
          params.status = "CONFIRMATION";
        }
        updateBooking(params).finally(() => {
          setSelected(null);
          history.push("/home");
        });
      }),
      []
    );

    const headerProps = {
      title: "Assign Driver and Unit",
      subheader: "#" + selected,
    };

    return (
      <FormProvider {...methods}>
        <ModalForm
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          open={open}
          headerProps={headerProps}
        >
          {<ReviewBookingForm id={selected} />}
        </ModalForm>
      </FormProvider>
    );
  } else {
    return null;
  }
};
