import { entitySelector } from "app/utility/entitySelectors";
import React from "react";
import { selectMapper } from "../../utility/selectMapper";
import { GeofenceDto } from "../dto/geofence.dto";
import { useGetGeofencesQuery } from "../wialonServices";

export const useGeofence = () => {
  const { data, error, isFetching: isLoading, refetch } = useGetGeofencesQuery();

  const selectors = entitySelector<GeofenceDto>(data);

  if (data) {
    selectors.selectOptions = selectMapper(data.entities, {
      label: "n",
      value: "id",
      latlng: "latlng"
    })
  }

  const selectStringOptions = selectMapper(data ? data.entities : [], {
    label: "n",
    value: "n",
    latlng: "latlng"
  })

  return React.useMemo(() => {
    return {
      data,
      error,
      isLoading,
      refetch,
      ...selectors,
      selectStringOptions
    };
  }, [data, error, isLoading, refetch, selectors, selectStringOptions]);
};
