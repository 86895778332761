import { api } from "app";
import { LatLngLiteral } from "leaflet";
import { GisRouteWialonDto, IGisRouteWialonDto } from "./dto/gis-route.dto";
import { RouteDto } from "./dto/route.dto";

const URL = "routes";

const routeServices = api.injectEndpoints({
  endpoints: (build) => ({
    getRouteById: build.query<RouteDto, number | string>({
      query: (id) => ({ url: `${URL}/${id}`, method: "GET" }),
      providesTags: [{ type: "Units" }],
    }),
    getGisRoute: build.query<GisRouteWialonDto, IGisRouteWialonDto>({
      query: (params) => ({
        url: `wialon/gis-route`,
        method: "GET",
        params: params,
      }),
      providesTags: [{ type: "Units" }],
    }),
    getGisGeocode: build.query<[string], LatLngLiteral>({
      query: (params) => ({
        url: `wialon/gis-geocode`,
        method: "GET",
        params: params,
      }),
      providesTags: [{ type: "Units" }],
    }),
  }),
  overrideExisting: false,
});

export default routeServices;

export const {
  useGetRouteByIdQuery,
  useGetGisRouteQuery,
  useLazyGetGisRouteQuery,
  useGetGisGeocodeQuery,
  useLazyGetGisGeocodeQuery,
} = routeServices;
