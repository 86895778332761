import AccountCircle from "@mui/icons-material/AccountCircle";
import ExitToApp from "@mui/icons-material/ExitToApp";
import { Divider, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import useIsMounted from "app/hooks/useIsMounted";
import { useAuth } from "app/services/hooks/useAuth";
import React from "react";
import { useHistory } from "react-router-dom";

export const AccountMenu = () => {
  const history = useHistory();
  const { handleLogout } = useAuth();
  const isMounted = useIsMounted();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    console.log("Account Menu");
  }, [isMounted]);

  return (
    <>
      <Tooltip title='Account settings'>
        <IconButton
          onClick={handleClick}
          aria-label='settings'
          aria-haspopup='true'
          color='inherit'
        >
          <AccountCircle />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "left", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <MenuItem onClick={() => history.push("/profile")}>
          <IconButton
            aria-label='account of current user'
            aria-controls='primary-search-account-menu'
            aria-haspopup='true'
            color='inherit'
          >
            <AccountCircle />
          </IconButton>
          <Typography>Profiles</Typography>
        </MenuItem>
        <Divider />
        {/* <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Settings fontSize='small' />
          </ListItemIcon>
          <Typography>Settings</Typography>
        </MenuItem> */}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <ExitToApp fontSize='small' />
          </ListItemIcon>
          <Typography>Logout</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
