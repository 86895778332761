import React, { useEffect } from "react";
import { useSse } from "../../hooks/useSse";
import { authSelector, logout } from "../../slices/authSlice";
import { useAppDispatch, useAppSelector } from "../../slices/generic";
import {
  useLazyCheckAccessTokenQuery,
  useLazyGetWialonTokenQuery,
  useLoginMutation,
} from "../authServices";
import { LoginDto } from "../dto/user.dto";
import wialonServices from "../wialonServices";

export const useAuthProvider = () => {
  const { subscribe, unsubscribe } = useSse();
  const [
    checkAccessToken,
    { data: refreshUserData, isLoading: tokenLoading, isSuccess: tokenSuccess },
  ] = useLazyCheckAccessTokenQuery({
    refetchOnReconnect: true,
  });
  const [
    getWialon,
    { isLoading: wialonLoading },
  ] = useLazyGetWialonTokenQuery();
  const [
    login,
    { data: loginData, isLoading: loginLoading },
  ] = useLoginMutation();
  const dispatch = useAppDispatch();
  const handleLogin = React.useCallback(
    (props: LoginDto) =>
      login(props)
        .unwrap()
        .then(({ role, id }) => {
          if (role && id) subscribe(role, id);
        }),
    [login]
  );

  const handleLogout = React.useCallback(() => {
    dispatch(logout());
    unsubscribe();
  }, [logout, unsubscribe]);

  const getWialonToken = React.useCallback(() => getWialon(), [getWialon]);

  const selectors = useAppSelector(authSelector);

  const isLoading = loginLoading || tokenLoading || wialonLoading;

  const [
    trigger,
  ] = wialonServices.endpoints.getAvlEvents.useLazyQuerySubscription({
    pollingInterval: 60000,
    refetchOnReconnect: true,
  });

  const triggerEvents = React.useCallback(() => {
    if (selectors?.wialon_sid) {
      console.log(selectors?.wialon_sid);
      trigger(selectors?.wialon_sid);
    }
  }, [trigger, selectors?.wialon_sid]);

  return React.useMemo(() => {
    return {
      handleLogin,
      isLoading,
      handleLogout,
      ...selectors,
      getWialonToken,
      triggerEvents,
      tokenLoading,
      checkAccessToken,
    };
  }, [
    handleLogin,
    checkAccessToken,
    isLoading,
    logout,
    selectors,
    getWialonToken,
    triggerEvents,
    handleLogout,
    authSelector,
    useAppSelector,
    tokenLoading,
  ]);
};

export const AuthContext = React.createContext<ReturnType<
  typeof useAuthProvider
> | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isChecked, setIsChecked] = React.useState(false);
  const props = useAuthProvider();

  useEffect(() => {
    if (!isChecked) {
      const accessToken = sessionStorage.getItem("rememberAccessToken") || "";
      if (accessToken) {
        console.log("load once");
        props.checkAccessToken(accessToken);
      }
    }
    setIsChecked(true);
  }, []);

  return <AuthContext.Provider value={props}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return React.useContext(AuthContext) as ReturnType<typeof useAuthProvider>;
};
