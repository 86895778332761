import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { api } from "app";
import { UnitDto } from "app/services/dto/unit.dto";
import { GeofenceDto } from "./dto/geofence.dto";

const URL = "wialon/ajax.html";

const wialonServices = api.injectEndpoints({
  endpoints: (build) => ({
    getAvlEvents: build.query<EntityState<UnitDto>, string>({
      query: (sid) => ({ url: `https://hst-api.wialon.com/avl_evts?${new URLSearchParams({ sid: String(sid) })}`, method: "POST" }),
      providesTags: [{ type: "Units" }],
    }),
    getGeofences: build.query<EntityState<GeofenceDto>, void>({
      query: () => ({ url: `wialon/geofences`, method: "GET" }),
      providesTags: [{ type: "Units" }],
      transformResponse: (response: { data: GeofenceDto[] }) => {
        return geofenceAdapter.addMany(
          geofenceAdapter.getInitialState(),
          response.data
        )
      }
    }),
    getDrivers: build.query<EntityState<GeofenceDto>, void>({
      query: () => ({ url: `wialon/drivers`, method: "GET" }),
      providesTags: [{ type: "Units" }],
      transformResponse: (response: { data: GeofenceDto[] }) => {
        return geofenceAdapter.addMany(
          geofenceAdapter.getInitialState(),
          response.data
        )
      }
    }),
    getUnavailableDrivers: build.query<any[], number | undefined>({
      query: (date) => ({ url: `bookings/drivers/available/${date}`, method: "GET" }),
      providesTags: [{ type: "Units" }],
    }),
    getVehiclesById: build.query<any, number | string>({
      query: (id) => ({ url: `wialon/units/${id}`, method: "GET" }),
      providesTags: [{ type: "Jobs" }],
    }),
  }),
  overrideExisting: false,
});

export default wialonServices;

export const {
  useGetAvlEventsQuery,
  useGetGeofencesQuery,
  useGetVehiclesByIdQuery,
  useGetDriversQuery,
  useGetUnavailableDriversQuery
} = wialonServices;

const geofenceAdapter = createEntityAdapter<GeofenceDto>({});




