import { UserProvider } from "../Providers/UserProvider";
import { EditUserFields } from "./EditUserFields";

export const EditUserForm = ({ id, type }: { id: number; type: "ADMIN" | "DRIVER" | "CLIENT" }) => {
	return (
		<UserProvider id={id}>
			<EditUserFields type={type} />
		</UserProvider>
	);
};
