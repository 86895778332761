import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import ModalForm from "shared/Modal";
import { CreateModalProps } from "../../../app/hooks/useModal";
import { createUserResolver } from "../../../app/resolvers/create-user.resolver";
import { UserDto } from "../../../app/services/dto/user.dto";
import { useCreateUserMutation } from "../../../app/services/userServices";
import { EditUserFields } from "../Fields/EditUserFields";
import { PasswordFields } from "../Fields/PasswordFields";

export const CreateUserModal = ({
	selected,
	setSelected,
	type,
}: CreateModalProps & { type: "ADMIN" | "DRIVER" | "CLIENT" }) => {
	if (selected) {
		const methods = useForm({ resolver: yupResolver(createUserResolver) });
		const [createUser] = useCreateUserMutation();

		const open = React.useMemo(() => selected, [selected]);
		const handleClose = React.useCallback(() => setSelected(false), [setSelected]);

		const handleSubmit = React.useCallback(
			methods.handleSubmit<UserDto>((params: UserDto) => {
				createUser({ ...params, role: type });
				setSelected(false);
			}),
			[]
		);

		const headerProps = {
			title: "Create User",
			subheader: "#" + selected,
		};

		return (
			<FormProvider {...methods}>
				<ModalForm handleClose={handleClose} handleSubmit={handleSubmit} open={open} headerProps={headerProps}>
					<EditUserFields type={type} />
					<PasswordFields />
				</ModalForm>
			</FormProvider>
		);
	} else {
		return null;
	}
};
