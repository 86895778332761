import { Chip } from "@mui/material";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router";
import BaseTable from "shared/Table";
import { CustomFilterComponent } from "shared/Table/CustomFilterComponent";
import {
  customDateFilter,
  customTextAreaFilter,
} from "shared/Table/customFilters";
import { useGetPendingBookingsQuery } from "../../../app/services/bookingServices";
import { BookingDto } from "../../../app/services/dto/booking.dto";
import { useAuth } from "../../../app/services/hooks/useAuth";
import { entitySelector } from "../../../app/utility/entitySelectors";
import { CancelBookingModal } from "../../Booking/Modals/CancelBookingModal";
import { EditBookingModal } from "../../Booking/Modals/EditBookingModal";
import { bookingTableProps } from "./tableProps";

export const PendingBookingsTable = () => {
  const { refetch, table, isFetching: isLoading } = useGetPendingBookingsQuery(
    undefined,
    {
      selectFromResult: ({ data, ...params }) => ({
        ...entitySelector(data),
        ...params,
      }),
    }
  );
  const history = useHistory();
  const [selectedEdit, setSelectedEdit] = React.useState<null | number>(null);
  const [selectedCancel, setSelectedCancel] = React.useState<null | number>(
    null
  );
  const { role } = useAuth();

  const pendingTableProps = bookingTableProps({
    refetch,
    table,
    isLoading,
    title: "Pending Trips",
    actions: ["ADMIN", "SUPERUSER"].includes(role)
      ? [
          {
            icon: "pageview",
            tooltip: "Review Trip",
            onClick: (_e, rowData) => {
              history.push("/bookings/review/" + (rowData as BookingDto).id);
            },
          },
          {
            icon: "block",
            tooltip: "Cancel Trip",
            onClick: (_e, rowData) => {
              const isRowData = rowData as BookingDto;
              if (isRowData?.id) {
                setSelectedCancel(isRowData.id);
              }
            },
          },
        ]
      : [],
    role,
    columns: [
      {
        id: "id",
        title: "Booking ID",
        field: "id",
        customFilterAndSearch: customTextAreaFilter,
        defaultSort: "desc",
      },
      {
        id: "id",
        title: "Vehicle Type",
        field: "vehicleType",
      },

      {
        id: "id",
        title: "Project",
        field: "routes[0].start.project",
        type: "date",
      },
      {
        id: "id",
        title: "Status",
        field: "status",
        render: (rowData) => {
          const data = rowData.status;
          const isInProgress = rowData.status == "INPROGRESS";
          if (isInProgress) {
            const routes = rowData.routes[0] as any;
            const isFinishedPickup = routes.start.status == "COMPLETED";
            return isFinishedPickup ? routes.start.status : routes.start.status;
          }
          return data;
        },
        cellStyle: (rowData: string) => {
          return {
            color:
              rowData == "COMPLETED"
                ? "green"
                : rowData == "REJECTED" || rowData == "CANCELLED"
                ? "red"
                : "black",
          };
        },
      },
      {
        id: "id",
        title: "Pickup Date",
        field: "dateRequested",
        type: "datetime",
        customFilterAndSearch: customDateFilter,
        filterComponent: CustomFilterComponent,
      },

      {
        id: "id",
        title: "Creation Date",
        field: "createdAt",
        type: "datetime",
      },
      {
        id: "id",
        title: "Action Status",
        field: "dateRequested",
        render: (rowData) => {
          const dateRequested = "" + rowData.dateRequested;
          const isToday = moment(dateRequested).isSame(new Date(), "day");
          const nextDay = moment(dateRequested).isSame(
            moment(new Date()).add(1, "day"),
            "day"
          );
          const futureDates = moment(dateRequested)
            .add(1, "day")
            .isAfter(new Date(), "day");

          if (isToday) {
            return (
              <Chip
                label={"Same Day"}
                color={"success"}
                sx={{ alignSelf: "center" }}
              />
            );
          }
          if (nextDay) {
            return (
              <Chip
                label={"Next Day"}
                color={"warning"}
                sx={{ alignSelf: "center" }}
              />
            );
          }
          if (futureDates) {
            return (
              <Chip
                label={"Future Date"}
                color={"primary"}
                sx={{ alignSelf: "center" }}
              />
            );
          }

          // if (isToday) {
          //   const startTime = moment().startOf("day").add(6, "hours");
          //   const endTime = moment()
          //     .startOf("day")
          //     .add(14, "hours")
          //     .add(30, "minutes");
          //   const isInsideCutoff = moment(createdAt).isBetween(
          //     startTime,
          //     endTime
          //   );

          //   return isInsideCutoff ? (
          //     <Chip
          //       label={"Same Day"}
          //       color={"success"}
          //       sx={{ alignSelf: "center" }}
          //     />
          //   ) : (
          //     <Chip
          //       label={"Next Day"}
          //       color={"error"}
          //       sx={{ alignSelf: "center" }}
          //     />
          //   );
          // }

          return (
            <Chip
              label={"Need Action"}
              color={"success"}
              sx={{ alignSelf: "center" }}
            />
          );
        },
        cellStyle: () => ({
          alignItems: "center",
        }),
      },
    ],
  });

  return (
    <>
      <BaseTable {...pendingTableProps} />
      <EditBookingModal selected={selectedEdit} setSelected={setSelectedEdit} />
      <CancelBookingModal
        selected={selectedCancel}
        setSelected={setSelectedCancel}
      />
    </>
  );
};
