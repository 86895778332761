import { LatLngLiteral, LeafletMouseEvent } from "leaflet";
import { CircleMarker, useMapEvent } from "react-leaflet";

export const LocationSelector = ({
  handleLocation,
  latLng,
}: LocationSelectorProps) => {
  useMapEvent("dblclick", handleLocation);

  return latLng ? <CircleMarker center={latLng} radius={5} /> : null;
};

export type LocationSelectorProps = {
  handleLocation: (e: LeafletMouseEvent) => void;
  latLng: LatLngLiteral | null;
};
