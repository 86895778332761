import { FormControl } from "@mui/material";
import { LatLngLiteral } from "leaflet";
import { useMap } from "react-leaflet";
import Select from "react-select";
import { useGeofence } from "../../../app/services/hooks/useGeofence";
import {
  ProjectLocationDto,
  SelectMarkerDto,
} from "../../../app/slices/dto/select-marker.dto";
import { SelectOptions } from "../../../config/types";

export const SearchControlLoader = ({
  setLatLng,
  setProject,
}: SearchControlProps) => {
  const map = useMap();
  const { selectOptions } = useGeofence();

  return (
    <div className={`leaflet-top leaflet-right`}>
      <div className='leaflet-control leaflet-bar' style={{ width: 200 }}>
        {
          <FormControl fullWidth>
            <Select
              placeholder='Search available projects'
              options={selectOptions}
              isSearchable={true}
              isClearable={true}
              onChange={(e) => {
                if (e) {
                  const { latlng, value, label } = e as SelectOptions & {
                    latlng: LatLngLiteral;
                  };
                  if (latlng) {
                    if (setLatLng) {
                      setLatLng(latlng);
                    }
                    if (setProject) {
                      setProject({ value, label });
                    }
                    map.setView(latlng, 15, {
                      animate: true,
                      duration: 1,
                    });
                  }
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  const target = (e.target as any).value;
                  const isCoordinates = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/.test(
                    target
                  );
                  if (isCoordinates) {
                    const latlngtuple = target.split(",");
                    const latlng = {
                      lat: latlngtuple[0],
                      lng: latlngtuple[1],
                    } as LatLngLiteral;
                    if (setLatLng) {
                      setLatLng(latlng);
                      if (setProject) {
                        setProject({ label: "Custom Request", value: "0" });
                      }
                      map.setView(latlng, 15, {
                        animate: true,
                        duration: 1,
                      });
                    }
                  }
                }
              }}
              sx={{ innerWidth: 200 }}
              menuPlacement='auto'
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          </FormControl>
        }
      </div>
    </div>
  );
};

export type SearchControlProps = SelectMarkerDto & Partial<ProjectLocationDto>;
