import { Box, Toolbar } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useAuth } from "app/services/hooks/useAuth";
import Display from "features/Admin/Display";
import Page404 from "features/Admin/Page404";
import UnitTable from "features/Tables/units/UnitTable";
import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Copyright from "shared/Display/Copyright";
import { useGetAvlEventsQuery } from "../../app/services/wialonServices";
import { CreateBookingPage } from "../Map/page/CreateBooking";
import { ReviewBookingPage } from "../Map/page/ReviewBooking";
import { ViewBookingPage } from "../Map/page/ViewBooking";
import { ViewBookingSummary } from "../Map/page/ViewBookingSummary";
import { PrimaryAppBar } from "../Navigation/AppBar/PrimaryAppBar";
import { AppDrawer } from "../Navigation/Drawer/AppDrawer";
import { BookedBookingsTable } from "../Tables/bookings/BookedBookingsTable";
import { CompletedBookingsTable } from "../Tables/bookings/CompletedBookingsTable";
import { PendingBookingsTable } from "../Tables/bookings/PendingBookingsTable";
import { SummaryBookingsTable } from "../Tables/bookings/SummaryBookingsTable";
import { BookingDriverReportProvider } from "../Tables/reports/BookingDriverReportProvider";
import { BookingReportTable } from "../Tables/reports/BookingReportTable";
import AdminTable from "../Tables/users/AdminTable";
import ClientTable from "../Tables/users/ClientTable ";
import DriverTable from "../Tables/users/DriverTable";
import Profile from "./Profile";

const AdminBase = () => {
  const { role, wialon_sid, getWialonToken } = useAuth();

  const { error } = useGetAvlEventsQuery(String(wialon_sid || ""), {
    pollingInterval: 120000,
    refetchOnReconnect: true,
  });

  const route = [
    {
      path: `/home`,
      component: Display,
      role: ["SUPERUSER", "ADMIN", "CLIENT"],
    },
    {
      path: `/profile`,
      component: Profile,
      role: ["SUPERUSER", "ADMIN", "CLIENT"],
    },
    {
      path: `/bookings/table/pending`,
      component: PendingBookingsTable,
      role: ["SUPERUSER", "ADMIN", "CLIENT"],
    },
    {
      path: `/bookings/table/booked`,
      component: BookedBookingsTable,
      role: ["SUPERUSER", "ADMIN", "CLIENT"],
    },
    {
      path: `/bookings/table/completed`,
      component: CompletedBookingsTable,
      role: ["SUPERUSER", "ADMIN", "CLIENT"],
    },
    {
      path: `/bookings/summary`,
      component: SummaryBookingsTable,
      role: ["SUPERUSER", "ADMIN", "CLIENT"],
    },
    {
      path: `/bookings/summary/:id`,
      component: ViewBookingSummary,
      role: ["SUPERUSER", "ADMIN", "CLIENT"],
    },
    {
      path: `/admins/table`,
      component: AdminTable,
      role: ["SUPERUSER", "ADMIN", "CLIENT"],
    },
    {
      path: `/clients/table`,
      component: ClientTable,
      role: ["SUPERUSER", "ADMIN", "CLIENT"],
    },
    {
      path: `/drivers/table`,
      component: DriverTable,
      role: ["SUPERUSER", "ADMIN", "CLIENT"],
    },
    {
      path: `/vehicles/table`,
      component: UnitTable,
      role: ["SUPERUSER", "ADMIN", "CLIENT"],
    },
    {
      path: `/bookings/create`,
      component: CreateBookingPage,
      role: ["SUPERUSER", "ADMIN", "CLIENT"],
    },
    {
      path: `/bookings/review/:id`,
      component: ReviewBookingPage,
      role: ["SUPERUSER", "ADMIN", "CLIENT"],
    },
    {
      path: `/bookings/view/:id`,
      component: ViewBookingPage,
      role: ["SUPERUSER", "ADMIN", "CLIENT"],
    },
    {
      path: `/reports/general`,
      component: BookingReportTable,
      role: ["SUPERUSER", "ADMIN", "CLIENT"],
    },
    {
      path: `/reports/driver`,
      component: BookingDriverReportProvider,
      role: ["SUPERUSER", "ADMIN", "CLIENT"],
    },
  ];

  useEffect(() => {
    if (error) {
      getWialonToken();
    }
  }, [error]);

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <Router>
        <Box sx={{ display: "flex" }}>
          <PrimaryAppBar />
          <AppDrawer />
          <Box
            component='main'
            sx={{
              flexGrow: 1,
              p: 3,
              flexDirection: "column",
              maxHeight: "70vh",
              padding: 6,
            }}
          >
            <Toolbar />
            <Switch>
              <Grid
                container
                component={Route}
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? "#f9fafb"
                      : theme.palette.grey[100],
                  flexDirection: "column",
                  overflow: "auto",
                  flex: 1,
                }}
                maxWidth='lg'
              >
                {route.map((item, index) => {
                  if (item.role.includes(role || "")) {
                    return (
                      <Route
                        path={item.path}
                        component={item.component}
                        key={index}
                        exact={true}
                      />
                    );
                  }
                  return null;
                })}
                <Copyright />

                <Route exact path='/'>
                  <Redirect to='/home' />
                </Route>
                <Route path='/error' component={Page404} />
                {/* <Redirect to='/error' /> */}
              </Grid>
            </Switch>
          </Box>
        </Box>
      </Router>
    </Box>
  );
};

export default AdminBase;
