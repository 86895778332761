import FieldText from "../../../shared/Input/Text";
import { UnitDriverFields } from "../../Unit/Fields/UnitDriverFields";
import { BookingProvider } from "../Providers/BookingProvider";

export const ReplaceBookingForm = ({ id }: { id: number }) => {
  return (
    <BookingProvider id={id}>
      <FieldText
        name='replacementReason'
        label='Replacement Reason'
        inputProps={{
          multiline: true,
        }}
        rules={{ required: "This field is required" }}
      />
      <UnitDriverFields />
    </BookingProvider>
  );
};
