import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItemButton } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import LinkedItem, { LinkedItemProps } from "./index";

const NestedLinkedItem = ({ title, route, icon }: LinkedItemProps) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open}>
        <List sx={{ pl: 3 }}>
          {route &&
            route.map((item, index) => {
              if (item.route) {
                return <NestedLinkedItem {...item} key={index} />;
              } else if (item.isAllowed) {
                return <LinkedItem {...item} key={index} />;
              }
              return null;
            })}
        </List>
      </Collapse>
    </>
  );
};

export default NestedLinkedItem;
