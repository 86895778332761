import { Typography } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import ModalForm from "shared/Modal";
import { EditModalProps } from "../../../app/hooks/useModal";
import { useUpdateBookingByIdMutation } from "../../../app/services/bookingServices";
import { UnitDto } from "../../../app/services/dto/unit.dto";

export const CompleteBookingModal = ({
  selected,
  setSelected,
}: EditModalProps) => {
  if (selected != null) {
    const history = useHistory();
    const methods = useForm();

    const open = React.useMemo(() => Boolean(selected != null), [selected]);
    const handleClose = React.useCallback(() => setSelected(null), [
      setSelected,
    ]);

    const [updateBooking] = useUpdateBookingByIdMutation();

    const handleSubmit = methods.handleSubmit<UnitDto>((params: UnitDto) => {
      if (selected) {
        params.id = selected;
        params.status = "COMPLETED";
      }
      updateBooking(params);
      setSelected(null);
      history.push("/home");
    });

    const headerProps = {
      title: "Complete the Booking",
      subheader: "#" + selected,
    };

    return (
      <FormProvider {...methods}>
        <ModalForm
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          open={open}
          headerProps={headerProps}
        >
          <Typography variant='h4'>
            Do you wish to confirm that the Order is Completed
          </Typography>
        </ModalForm>
      </FormProvider>
    );
  } else {
    return null;
  }
};
