import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import FieldSelect from "shared/Input/Select";
import FieldText from "shared/Input/Text";
import ModalForm from "shared/Modal";
import { EditModalProps } from "../../../app/hooks/useModal";
import { useUpdateBookingByIdMutation } from "../../../app/services/bookingServices";
import { UnitDto } from "../../../app/services/dto/unit.dto";
import { selectMapper } from "../../../app/utility/selectMapper";
import { BookingDetailFields } from "../Fields/BookingDetailFields";

export const CancelBookingModal = ({
  selected,
  setSelected,
}: EditModalProps) => {
  if (selected != null) {
    const history = useHistory();
    const methods = useForm();

    const open = React.useMemo(() => Boolean(selected != null), [selected]);
    const handleClose = React.useCallback(() => setSelected(null), [
      setSelected,
    ]);

    const [updateBooking] = useUpdateBookingByIdMutation();

    const handleSubmit = methods.handleSubmit<UnitDto>((params: UnitDto) => {
      if (selected) {
        params.id = selected;
        params.status = "REJECTED";
      }
      updateBooking(params);
      setSelected(null);
    });

    const headerProps = {
      title: "Cancel Booking",
      subheader: "#" + selected,
    };

    const list = [
      "REQUESTED VEHICLE UNAVAILABLE",
      "INCORRECT REQUEST",
      "DRIVER UNAVAILABLE",
      "UNABLE TO FULFILL IN TIME",
    ];

    return (
      <FormProvider {...methods}>
        <ModalForm
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          open={open}
          headerProps={headerProps}
        >
          <BookingDetailFields id={selected} />
          <FieldSelect
            name='cancelReason'
            label='Cancellation Category'
            options={selectMapper(list)}
            rules={{ required: "This field is required" }}
          />
          <FieldText
            name='cancelReasonNotes'
            label='Cancellation Reason(Optional)'
            inputProps={{
              multiline: true,
            }}
          />
        </ModalForm>
      </FormProvider>
    );
  } else {
    return null;
  }
};
