import { FC } from "react";
import { useForm } from "react-hook-form";
import { MapContainer, MapContainerProps, TileLayer } from "react-leaflet";

export const BaseMap: FC<MapContainerProps> = ({ children, ...props }) => {
  const methods = useForm();

  return (
    <MapContainer
      center={[25.0971745, 55.1806269]}
      zoom={13}
      scrollWheelZoom={true}
      doubleClickZoom={false}
      style={{ height: "100%", maxHeight: "80vh" }}
      {...props}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      {children}
    </MapContainer>
  );
};

export interface BaseMapProps {
  height: string | number;
  mapComponents: {
    props: Record<string, any>;
    component: React.ComponentType<any>;
  }[];
}
