import { LinearProgress } from "@mui/material";
import { createContext } from "react";
import { useGetBookingByIdQuery } from "../../../app/services/bookingServices";
import { BookingDto } from "../../../app/services/dto/booking.dto";
import { SimpleProviderProps } from "../../types";

export const BookingContext = createContext<Partial<BookingDto>>({});

export const BookingProvider = ({ id, children }: SimpleProviderProps) => {
  const { data, isFetching } = useGetBookingByIdQuery(id as number);
  return isFetching ? (
    <LinearProgress />
  ) : data ? (
    <BookingContext.Provider value={data}>{children}</BookingContext.Provider>
  ) : (
    <LinearProgress />
  );
};
