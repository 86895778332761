import React from "react";
import { BookedBookingProvider } from "../../../app/services/hooks/useBookedBooking";
import { CompletedBookingProvider } from "../../../app/services/hooks/useCompletedBooking";

export const BookingListProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <BookedBookingProvider>
      <CompletedBookingProvider>
        <>{children}</>
      </CompletedBookingProvider>
    </BookedBookingProvider>
  );
};
