import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import authServices from "app/services/authServices";
import { AuthDto } from "app/services/dto/user.dto";
import { RootState } from "app/store";

const initialState: AuthDto = {
  id: null,
  role: "",
  name: "",
  permissions: [],
  access_token: "",
  wialon_sid: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state = Object.assign(state, initialState);
      sessionStorage.removeItem("rememberAccessToken");
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        authServices.endpoints.login.matchFulfilled,
        authServices.endpoints.checkAccessToken.matchFulfilled
      ),
      (state, { payload: data }) => {
        if (data) {
          Object.assign(state, data);
          if (data.access_token) {
            sessionStorage.setItem("rememberAccessToken", data.access_token);
          }
        }
      }
    );
  },
});

// Exportable Actions
export const { logout } = authSlice.actions;

// Commonly Used Data
export const authSelector = ({ auth }: RootState) => ({
  name: auth.name,
  id: auth.id,
  role: auth.role,
  isAuthenticated: Boolean(auth.access_token),
  wialon_sid: auth.wialon_sid,
});

export default authSlice.reducer;
