import { UserDto } from "app/services/dto/user.dto";
import {
  useCreateUserMutation,
  useGetClientsQuery,
} from "app/services/userServices";
import { entitySelector } from "app/utility/entitySelectors";
import React from "react";

export const useClientProvider = () => {
  const { data, error, isFetching: isLoading, refetch } = useGetClientsQuery();

  const [createUser] = useCreateUserMutation();

  const selectors = entitySelector<UserDto>(data);

  return React.useMemo(() => {
    return {
      data,
      error,
      isLoading,
      refetch,
      createUser,

      ...selectors,
    };
  }, [data, error, isLoading, refetch, createUser, selectors]);
};

export const ClientContext = React.createContext<ReturnType<
  typeof useClientProvider
> | null>(null);

export const ClientProvider = ({ children }: { children: React.ReactNode }) => {
  const props = useClientProvider();
  return (
    <ClientContext.Provider value={props}> {children} </ClientContext.Provider>
  );
};

export const useClient = () => {
  return React.useContext(ClientContext) as ReturnType<
    typeof useClientProvider
  >;
};
