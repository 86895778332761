import { blueGrey, indigo, lightGreen } from "@mui/material/colors";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import type {} from "@mui/x-data-grid/themeAugmentation";

export const baseTheme = createTheme({
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: indigo[500],
    },
    secondary: {
      main: lightGreen[600],
      contrastText: "#fff",
    },
    grey: blueGrey,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        // overflow: "auto",
        fontSize: "0.675 rem",
        body: {
          "&::-webkit-scrollbar": {
            width: "0.8em",
          },
          "&::-webkit-scrollbar-track": {
            display: "none",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#babac0",
            borderRadius: 16,
            padding: 0,
            margin: 0,
          },
        },
      },
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          "&:nth-child(odd)": {
            backgroundColor: blueGrey[50],
          },
          "&:hover": {
            backgroundColor: indigo[200] + " !important",
          },
        },
      },
    },
  },
});

export const theme = responsiveFontSizes(baseTheme, { factor: 2 });
