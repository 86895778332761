import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Typography } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { changePasswordResolver } from "../../../app/resolvers/create-user.resolver";
import { UserDto } from "../../../app/services/dto/user.dto";
import { useAuth } from "../../../app/services/hooks/useAuth";
import { useChangePasswordMutation } from "../../../app/services/userServices";
import { PasswordFields } from "../Fields/PasswordFields";

export const ChangePasswordForm = () => {
  const { id } = useAuth();

  if (id) {
    const methods = useForm({ resolver: yupResolver(changePasswordResolver) });
    const [changePassword] = useChangePasswordMutation();

    const handleSubmit = React.useCallback(
      methods.handleSubmit<UserDto>((params: UserDto) => {
        changePassword({ ...params, id });
      }),
      []
    );

    return (
      <FormProvider {...methods}>
        <PasswordFields />
        <Button onClick={handleSubmit}>
          <Typography>{"Change Password"}</Typography>
        </Button>
      </FormProvider>
    );
  } else {
    return null;
  }
};
